<template>
  <div class="content">
    <div class="itemCont" v-for="(item, index) in pdfData" :key="index">
      <div class="pinBottom">
        <div class="pinItem">
          <div class="titleInfo">拼音</div>
          <div class="layer layer1">{{ item.pinyin }}</div>
          <div class="titleInfo">部首</div>
          <div class="layer">{{ item.radicals }}</div>
          <div class="titleInfo">笔画</div>
          <div class="layer">{{ item.strokes }}</div>
        </div>
        <div class="componentBox">
          <svg v-for="(item1, index) in item.resultArr" :key="index">
            <g transform="translate(2, 26) scale(0.026, -0.026)">
              <path
                :d="item2"
                v-for="(item2, index) in item1"
                :key="index"
              ></path>
            </g>
          </svg>
        </div>
      </div>
      <div class="pinBox">
        <div class="pinGbox">
          <div class="line"></div>
          <div class="line1"></div>
          <div class="pinGe">{{ item.pinyin }}</div>
        </div>
        <div class="pinGbox">
          <div class="line"></div>
          <div class="line1"></div>
          <div class="pinGe">{{ item.pinyin }}</div>
        </div>
        <div class="pinGbox">
          <div class="line"></div>
          <div class="line1"></div>
          <div class="pinGe">{{ item.pinyin }}</div>
        </div>
        <div class="pinGbox">
          <div class="line"></div>
          <div class="line1"></div>
          <div class="pinGe">{{ item.pinyin }}</div>
        </div>
        <div class="pinGbox">
          <div class="line"></div>
          <div class="line1"></div>
          <div class="pinGe">{{ item.pinyin }}</div>
        </div>
        <div class="pinGbox">
          <div class="line"></div>
          <div class="line1"></div>
          <div class="pinGe">{{ item.pinyin }}</div>
        </div>
        <div class="pinGbox">
          <div class="line"></div>
          <div class="line1"></div>
          <div class="pinGe">{{ item.pinyin }}</div>
        </div>
        <div class="pinGbox">
          <div class="line"></div>
          <div class="line1"></div>
          <div class="pinGe">{{ item.pinyin }}</div>
        </div>
        <div class="pinGbox">
          <div class="line"></div>
          <div class="line1"></div>
          <div class="pinGe">{{ item.pinyin }}</div>
        </div>
        <div class="pinGbox">
          <div class="line"></div>
          <div class="line1"></div>
          <div class="pinGe">{{ item.pinyin }}</div>
        </div>
        <div class="pinGbox">
          <div class="line"></div>
          <div class="line1"></div>
          <div class="pinGe">{{ item.pinyin }}</div>
        </div>
        <div class="pinGbox">
          <div class="line"></div>
          <div class="line1"></div>
          <div class="pinGe">{{ item.pinyin }}</div>
        </div>
      </div>
      <div class="ziBox">
        <div class="svgBox">
          <svg>
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="0"
              x2="60"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="60"
              y1="0"
              x2="0"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="30"
              y1="0"
              x2="30"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="30"
              x2="60"
              y2="30"
              stroke="#5CB87A"
            />

            <g transform="translate(8, 46) scale(0.04, -0.04)">
              <path
                :d="item2"
                v-for="(item2, index) in item.svg"
                :key="index"
              ></path>
            </g>
          </svg>
        </div>
        <div class="svgBox">
          <svg>
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="0"
              x2="60"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="60"
              y1="0"
              x2="0"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="30"
              y1="0"
              x2="30"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="30"
              x2="60"
              y2="30"
              stroke="#5CB87A"
            />

            <g transform="translate(8, 46) scale(0.04, -0.04)">
              <path
                :d="item2"
                v-for="(item2, index) in item.svg"
                :key="index"
              ></path>
            </g>
          </svg>
        </div>
        <div class="svgBox">
          <svg>
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="0"
              x2="60"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="60"
              y1="0"
              x2="0"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="30"
              y1="0"
              x2="30"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="30"
              x2="60"
              y2="30"
              stroke="#5CB87A"
            />

            <g transform="translate(8, 46) scale(0.04, -0.04)">
              <path
                :d="item2"
                v-for="(item2, index) in item.svg"
                :key="index"
              ></path>
            </g>
          </svg>
        </div>
        <div class="svgBox">
          <svg>
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="0"
              x2="60"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="60"
              y1="0"
              x2="0"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="30"
              y1="0"
              x2="30"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="30"
              x2="60"
              y2="30"
              stroke="#5CB87A"
            />

            <g transform="translate(8, 46) scale(0.04, -0.04)">
              <path
                :d="item2"
                v-for="(item2, index) in item.svg"
                :key="index"
              ></path>
            </g>
          </svg>
        </div>
        <div class="svgBox">
          <svg>
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="0"
              x2="60"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="60"
              y1="0"
              x2="0"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="30"
              y1="0"
              x2="30"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="30"
              x2="60"
              y2="30"
              stroke="#5CB87A"
            />

            <g transform="translate(8, 46) scale(0.04, -0.04)">
              <path
                :d="item2"
                v-for="(item2, index) in item.svg"
                :key="index"
              ></path>
            </g>
          </svg>
        </div>
        <div class="svgBox">
          <svg>
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="0"
              x2="60"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="60"
              y1="0"
              x2="0"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="30"
              y1="0"
              x2="30"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="30"
              x2="60"
              y2="30"
              stroke="#5CB87A"
            />

            <g transform="translate(8, 46) scale(0.04, -0.04)">
              <path
                :d="item2"
                v-for="(item2, index) in item.svg"
                :key="index"
              ></path>
            </g>
          </svg>
        </div>
        <div class="svgBox">
          <svg>
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="0"
              x2="60"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="60"
              y1="0"
              x2="0"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="30"
              y1="0"
              x2="30"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="30"
              x2="60"
              y2="30"
              stroke="#5CB87A"
            />

            <g transform="translate(8, 46) scale(0.04, -0.04)">
              <path
                :d="item2"
                v-for="(item2, index) in item.svg"
                :key="index"
              ></path>
            </g>
          </svg>
        </div>
        <div class="svgBox">
          <svg>
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="0"
              x2="60"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="60"
              y1="0"
              x2="0"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="30"
              y1="0"
              x2="30"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="30"
              x2="60"
              y2="30"
              stroke="#5CB87A"
            />

            <g transform="translate(8, 46) scale(0.04, -0.04)">
              <path
                :d="item2"
                v-for="(item2, index) in item.svg"
                :key="index"
              ></path>
            </g>
          </svg>
        </div>
        <div class="svgBox">
          <svg>
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="0"
              x2="60"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="60"
              y1="0"
              x2="0"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="30"
              y1="0"
              x2="30"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="30"
              x2="60"
              y2="30"
              stroke="#5CB87A"
            />

            <g transform="translate(8, 46) scale(0.04, -0.04)">
              <path
                :d="item2"
                v-for="(item2, index) in item.svg"
                :key="index"
              ></path>
            </g>
          </svg>
        </div>
        <div class="svgBox">
          <svg>
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="0"
              x2="60"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="60"
              y1="0"
              x2="0"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="30"
              y1="0"
              x2="30"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="30"
              x2="60"
              y2="30"
              stroke="#5CB87A"
            />

            <g transform="translate(8, 46) scale(0.04, -0.04)">
              <path
                :d="item2"
                v-for="(item2, index) in item.svg"
                :key="index"
              ></path>
            </g>
          </svg>
        </div>
        <div class="svgBox">
          <svg>
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="0"
              x2="60"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="60"
              y1="0"
              x2="0"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="30"
              y1="0"
              x2="30"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="30"
              x2="60"
              y2="30"
              stroke="#5CB87A"
            />

            <g transform="translate(8, 46) scale(0.04, -0.04)">
              <path
                :d="item2"
                v-for="(item2, index) in item.svg"
                :key="index"
              ></path>
            </g>
          </svg>
        </div>
        <div class="svgBox">
          <svg>
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="0"
              x2="60"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="60"
              y1="0"
              x2="0"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="30"
              y1="0"
              x2="30"
              y2="60"
              stroke="#5CB87A"
            />
            <line
              stroke-dasharray="3 2"
              x1="0"
              y1="30"
              x2="60"
              y2="30"
              stroke="#5CB87A"
            />

            <g transform="translate(8, 46) scale(0.04, -0.04)">
              <path
                :d="item2"
                v-for="(item2, index) in item.svg"
                :key="index"
              ></path>
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 导出页面为PDF格式
// import { SVG } from '@svgdotjs/svg.js'

export default {
  data() {
    return {
      pdfData: [
        {
          word: "阿",
          oldword: "阿",
          strokes: "7",
          pinyin: "ā",
          radicals: "阝",
          svg: [
            "M 228 673 Q 265 680 303 697 Q 319 707 331 696 Q 334 692 310 620 Q 289 563 297 549 Q 325 521 347 457 Q 353 439 345 430 Q 338 420 312 425 Q 281 432 248 435 Q 224 436 250 421 Q 310 391 363 353 Q 378 340 391 353 Q 406 363 412 389 Q 424 444 358 527 Q 318 567 343 605 Q 386 675 411 694 Q 430 707 421 720 Q 408 739 369 758 Q 347 767 333 754 Q 279 715 219 704 C 190 697 199 666 228 673 Z",
            "M 219 704 Q 173 734 155 739 Q 143 742 137 731 Q 133 724 142 712 Q 172 642 173 567 Q 182 335 163 208 Q 154 163 140 117 Q 131 86 137 64 Q 149 24 162 9 Q 175 -7 186 10 Q 222 46 222 207 Q 216 577 228 673 C 230 697 230 697 219 704 Z",
            "M 774 645 Q 823 649 885 649 Q 940 646 948 658 Q 952 668 937 682 Q 885 718 849 710 Q 732 683 428 638 Q 407 635 423 619 Q 436 606 454 601 Q 475 597 490 601 Q 610 635 716 640 L 774 645 Z",
            "M 477 498 Q 464 504 438 509 Q 426 512 423 507 Q 416 501 424 486 Q 449 432 463 344 Q 466 317 481 299 Q 497 278 503 293 Q 507 305 506 324 L 503 355 Q 490 443 489 470 C 488 494 488 494 477 498 Z",
            "M 633 367 Q 652 446 676 471 Q 698 496 676 510 Q 657 522 630 539 Q 612 548 567 524 Q 524 509 477 498 C 448 491 460 464 489 470 Q 531 479 574 488 Q 595 492 600 484 Q 607 478 600 444 Q 593 413 585 377 C 578 348 626 338 633 367 Z",
            "M 506 324 Q 518 324 531 325 Q 565 332 641 337 Q 651 338 652 348 Q 652 355 633 367 L 585 377 Q 579 377 575 375 Q 535 363 503 355 C 474 348 476 324 506 324 Z",
            "M 716 640 Q 734 586 735 562 Q 738 115 735 100 Q 732 90 727 83 Q 714 76 684 84 Q 662 90 634 96 Q 621 100 621 92 Q 621 83 633 73 Q 697 18 741 -27 Q 754 -37 769 -20 Q 812 40 808 126 Q 792 384 803 581 Q 810 611 803 622 Q 796 632 774 645 C 749 662 707 669 716 640 Z",
          ],
        },
        {
          word: "兵",
          oldword: "兵",
          strokes: "7",
          pinyin: "bīnɡ",
          radicals: "八",
          svg: [
            "M 383 657 Q 524 690 592 719 Q 644 737 689 743 Q 708 743 713 752 Q 717 764 705 778 Q 683 797 633 819 Q 617 829 601 828 Q 592 824 591 812 Q 587 772 368 677 C 341 665 354 650 383 657 Z",
            "M 385 545 Q 384 614 386 633 Q 387 646 383 657 C 381 668 381 668 368 677 Q 340 699 306 707 Q 288 713 278 705 Q 268 698 277 678 Q 305 632 318 581 Q 328 529 352 318 C 355 288 405 293 402 323 Q 392 432 387 507 L 385 545 Z",
            "M 618 531 Q 756 558 762 564 Q 772 573 767 582 Q 760 595 727 605 Q 700 611 591 581 Q 486 556 385 545 C 355 542 357 510 387 507 Q 426 501 524 515 Q 546 519 572 523 L 618 531 Z",
            "M 619 351 Q 646 457 661 487 Q 665 496 649 512 Q 633 524 618 531 C 591 544 560 550 572 523 Q 572 522 574 518 Q 589 494 576 345 C 573 315 612 322 619 351 Z",
            "M 352 318 Q 234 308 106 292 Q 84 291 100 271 Q 116 255 135 249 Q 159 243 176 248 Q 425 311 849 312 Q 874 312 899 311 Q 921 310 927 320 Q 933 333 915 348 Q 857 393 816 384 Q 737 369 619 351 L 576 345 Q 539 341 497 335 Q 451 331 402 323 L 352 318 Z",
            "M 351 201 Q 275 125 153 42 Q 138 29 155 22 Q 203 22 344 109 Q 404 154 427 159 Q 449 166 444 187 Q 437 205 401 239 Q 389 251 376 249 Q 358 245 362 229 Q 366 213 351 201 Z",
            "M 598 200 Q 668 133 750 35 Q 768 11 786 5 Q 796 4 805 15 Q 821 30 808 82 Q 795 148 601 236 Q 591 243 588 228 Q 587 212 598 200 Z",
          ],
        },
        {
          word: "蓟",
          oldword: "薳",
          strokes: "13",
          pinyin: "jì",
          radicals: "艹",
          svg: [
            "M 632 677 Q 647 677 857 678 Q 878 678 883 687 Q 889 699 871 713 Q 811 759 748 741 Q 703 735 649 724 L 595 716 Q 541 712 439 696 L 395 692 Q 284 682 161 667 Q 139 666 155 647 Q 170 632 188 626 Q 210 620 227 625 Q 309 647 402 658 L 443 663 Q 476 672 586 676 L 632 677 Z",
            "M 439 696 Q 436 736 434 766 Q 435 785 416 790 Q 376 808 356 801 Q 340 792 354 774 Q 384 738 394 696 Q 394 695 395 692 L 402 658 Q 417 603 427 591 Q 440 581 444 592 Q 448 605 443 663 L 439 696 Z",
            "M 649 724 Q 652 742 679 793 Q 692 809 682 818 Q 669 834 634 851 Q 618 860 600 850 Q 590 844 599 833 Q 611 805 595 716 L 586 676 Q 568 610 570 591 Q 571 578 587 589 Q 602 602 632 677 L 649 724 Z",
            "M 354 514 Q 355 517 358 519 Q 370 538 380 546 Q 387 553 384 564 Q 381 574 360 592 Q 341 607 326 607 Q 311 606 317 589 Q 327 568 284 491 Q 263 457 234 419 Q 227 412 224 407 Q 221 398 232 400 Q 260 404 335 491 L 354 514 Z",
            "M 404 405 Q 462 471 496 493 Q 512 502 501 513 Q 489 525 457 543 Q 442 547 430 539 Q 423 536 369 518 Q 362 517 354 514 C 325 505 306 497 335 491 Q 350 485 404 497 Q 410 498 411 497 Q 414 494 411 487 Q 390 430 371 398 C 356 372 384 383 404 405 Z",
            "M 238 372 Q 228 376 201 380 Q 189 381 187 377 Q 181 371 190 356 Q 218 301 239 208 Q 243 180 260 162 Q 278 143 282 157 Q 283 161 284 167 L 284 189 Q 283 202 280 218 Q 259 315 255 344 C 251 368 251 368 238 372 Z",
            "M 446 187 Q 458 171 469 166 Q 478 159 492 178 Q 505 199 532 312 Q 539 339 560 360 Q 572 370 562 382 Q 549 397 512 420 Q 499 426 431 411 Q 419 411 404 405 L 371 398 Q 364 398 238 372 C 209 366 227 332 255 344 Q 259 350 337 365 L 369 371 Q 403 378 444 385 Q 471 389 479 381 Q 489 368 487 355 Q 468 247 458 231 Q 455 222 439 217 C 426 210 426 210 446 187 Z",
            "M 393 280 Q 420 286 445 288 Q 464 291 457 302 Q 448 314 427 317 Q 414 320 394 314 L 349 302 Q 321 295 298 286 Q 285 283 301 271 Q 305 268 351 273 L 393 280 Z",
            "M 390 215 Q 391 249 393 280 L 394 314 Q 394 315 395 317 Q 395 351 380 363 Q 374 369 369 371 C 343 386 324 392 337 365 Q 346 350 349 302 L 351 273 Q 351 246 354 206 C 356 176 389 185 390 215 Z",
            "M 284 167 Q 288 166 296 167 Q 380 180 446 187 C 476 190 468 210 439 217 Q 438 218 435 218 Q 419 222 390 215 L 354 206 Q 317 199 284 189 C 255 180 254 170 284 167 Z",
            "M 163 84 Q 150 83 147 73 Q 143 60 152 50 Q 174 31 206 9 Q 216 5 229 12 Q 272 37 319 53 Q 389 75 460 99 Q 488 106 507 120 Q 522 126 523 135 Q 519 142 505 140 Q 217 77 163 84 Z",
            "M 596 459 Q 617 408 605 268 Q 599 241 624 210 Q 634 200 642 210 Q 657 234 658 303 Q 658 391 664 431 Q 670 452 651 463 Q 636 472 620 480 Q 608 484 600 479 Q 593 472 596 459 Z",
            "M 736 519 Q 740 492 740 86 Q 740 70 731 63 Q 722 59 634 76 Q 600 86 603 76 Q 604 69 626 55 Q 702 3 723 -29 Q 747 -66 764 -65 Q 780 -66 794 -30 Q 810 18 807 94 Q 776 430 809 547 Q 825 578 768 602 Q 734 620 713 613 Q 694 606 711 582 Q 733 554 736 519 Z",
          ],
        },
      ],
    };
  },

  created() {
    // var arr = [1,2,3,4,5,6,7]
    // var arr1 = [[1],[1,2],[1,2,3],[1,2,3,4],[1,2,3,4,5],[1,2,3,4,5,6],[1,2,3,4,5,6,7]]
    this.pdfData.map((item) => {
      item.resultArr = [];
      for (var i = 0; i < item.svg.length; i++) {
        var temp = [];
        for (var j = 0; j < item.svg.length; j++) {
          temp.push(item.svg[j]);
          if (i === j) {
            item.resultArr.push(temp);
            break;
          }
        }
      }
    });
  },
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
svg {
  width: 100%;
  height: 100%;
}
.content {
  width: 803px;
  height: 1000px;
  padding: 40px;
  border: 2px solid rgba(92, 184, 122, 1);
  margin: 0 auto;
}
.itemCont {
  width: 100%;
  height: 140px;
  border: 2px solid rgba(92, 184, 122, 1);
  box-sizing: border-box;
  border-bottom: none;
}
.itemCont:last-child {
  border-bottom: 2px solid rgba(92, 184, 122, 1);
}

.pinBottom {
  height: 42px;
  border-bottom: 2px solid rgba(92, 184, 122, 1);
  box-sizing: border-box;
  margin-left: -1px;
  margin-top: -1px;
  background-color: rgba(92, 184, 122, 0.2);
  line-height: 42px;
  display: flex;
}
.pinItem {
  width: 240px;
  height: 100%;
  border-right: 2px solid rgba(92, 184, 122, 1);
  display: flex;
  padding: 0 10px;
  box-sizing: border-box;
  margin-left: -2px;
}
.titleInfo {
  font-size: 16px;
  line-height: 42px;
  color: #333;
  width: 50px;
  text-align: center;
}
.layer {
  background-color: #fff;
  border-radius: 6px 6px 6px 6px;
  height: 30px;
  width: 50px;
  text-align: center;
  margin-top: 5px;
  line-height: 30px;
  font-weight: 600;
}

.componentBox {
  width: 550px;
}
.componentBox svg {
  width: 30px;
  height: 30px;
  margin-top: 5px;
}
.pinBox {
  height: 40px;
  border-bottom: 2px solid rgba(92, 184, 122, 1);
  display: flex;
  margin-left: -1px;
}
.pinBox svg {
}
.layer1 {
  font-family: "pinyin";
}
.pinGe {
  font-family: "pinyin";
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 25px;
}
.line {
  width: 100%;
  height: 13px;
  border-bottom: 1px dashed #458f1b;
}
.line1 {
  width: 100%;
  height: 13px;
  border-bottom: 1px dashed #458f1b;
}
.pinGbox {
  width: 60px;
  height: 40px;
  position: relative;
  border-right: 2px solid rgba(92, 184, 122, 1);
  box-sizing: border-box;
}
.pinGbox:last-child {
  border: none;
}
.svgBox {
  width: 60px;
  height: 59px;
  border-right: 2px solid rgba(92, 184, 122, 1);
  box-sizing: border-box;
}
.svgBox:last-child {
  border: none;
}
.ziBox {
  display: flex;
  margin-left: -1px;
  margin-top: -2px;
}
</style>
